.navbar {
    background: none;
    justify-content: space-between;
}

.navbar .nav {
    border-right: 1px solid #293540;
}

.application-menu.navbar {
    background-color: #fff;
    border-bottom: 1px solid #293540;
    width: 100%;
    position: absolute;
    z-index: 9999;
}

.navbar .logo-link {
    padding: 0;
    color: #293540;
    _text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.navbar .logo-link img {
    width: 2.5rem;
}

.navbar .logo-link .logo-title {
    font-weight: 700;
    color: #293540;
}

.navbar .logo-link .logo-title-secondary {
    font-weight: 900;
    color: #00A6ED;
}

.navbar .nav-link {
    color: #293540;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
}

.navbar .navbar-toggler {
    border: 0;
}

.navbar .navbar-toggler:focus {
    outline: none;
}

.navbar .navbar-toggler img {
    width: 50%;
}

.dropdown-toggle.nav-link {
    min-width: 6rem;
    text-align: center;
}

.navbar-expand-md .navbar-nav .dropdown-menu {
    border-radius: 0;
    width: 100vw;
    position: fixed;
    top: calc(3.375rem);
}

.application-menu .list-group-item-text .nav-link {
    padding-left: 0;
    font-weight: 300;
}

.tertiary-menu .nav-item .nav-link {
    text-align: left;
}

.nav-link > span:after {
    content: "";
    background: currentColor;
    display: inline-block;
    height: 2px;
    opacity: 0;
    transform: translate(0, 100%) translateY(0.25rem) scaleX(0);
    transition: transform 0.5s ease;
    width: 100%;
}

.nav-link:hover > span:after {
    opacity: 1;
    transform: translate(0, 100%) translateY(0.25rem) scaleX(1);
    transform-origin: center left;
}

.active.nav-link > span:after {
    content: "";
    background: currentColor;
    display: inline-block;
    height: 2px;
    opacity: 1;
    transform: translate(0, 100%) translateY(0.25rem) scaleX(1);
    transition: transform 0.5s ease;
    width: 100%;
}

.application-menu .separator {
    border-top: 1px solid #3d516f67;
}

.tertiary-menu .list-group,
.main-menu .list-group {
    box-shadow: none;
}

.tertiary-menu .list-group-item,
.main-menu .list-group-item {
    border: none;
    background: transparent;
    text-align: left;
    position: relative;
}

.tertiary-menu .dropdown-item.active,
.tertiary-menu .dropdown-item:active,
.main-menu .dropdown-item.active,
.main-menu .dropdown-item:active {
    color: #3D516F;
    text-decoration: none;
    background-color: transparent;
}

.tertiary-menu .dropdown-item:hover,
.tertiary-menu .dropdown-item:focus,
.main-menu .dropdown-item:hover,
.main-menu .dropdown-item:focus {
    color: #3D516F;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    border: none;
}

.tertiary-menu .nav-link img {
    width: 1rem;
    height: 1rem;
}

.tertiary-menu .nav-link span.social-text {
    font-size: 0.75rem;
    font-weight: 400;
}

footer .navbar {
    background: linear-gradient(0deg, #E3EDF7, #E3EDF7);
}

.footer-menu {
    width: 100%;
    max-width: 1068px;
    z-index: 10;
}

.footer-menu .list-group-item {
    border: none;
    background: transparent;
    text-align: left;
    position: relative;
}

.footer-menu .list-group-item-text .nav-link {
    padding: 0;
    color: inherit;
}

.footer-menu .field-ai-copyright-header {
    margin-top: 3rem;
}

.footer-menu .field-ai-copyright-text {
    margin-top: 2rem;
}

.footer-menu .list-group-item-heading {
    margin-top: 3rem;
}

.footer-menu .list-group-item .logo-title {
    font-weight: 700;
    position: relative;
    color: #141928;
}

.footer-menu .list-group-item .logo-title-secondary {
    font-weight: 900;
    color: #FF0037;
}

.footer-menu .contact-form {
    width: 100%;
}

.footer-menu .contact-form button {
    font-size: 80%;
}

@media only screen and (max-width: 734px) {
    .application-menu {
        transform: translate3d(0, -1px, 0) !important;
    }
}

@media (max-width: 576px) {
    .dropdown-toggle::after {
        display: none!important;
    }

    .tertiary-menu,
    .main-menu {
        border: none;
        box-shadow: none;
        background-color: transparent;
}

    .tertiary-menu .dropdown-item,
    .main-menu .dropdown-item {
        flex-direction: column;
        padding: 0!important;
    }

    .main-menu .dropdown-item .list-group .list-group-item {
       padding: 0;
    }

    .main-menu .dropdown-item .list-group-item-heading {
        display: none;
    }

    .tertiary-menu .form-group {
        margin-top: 0.25rem!important;
        margin-bottom: 0.5rem!important;
    }
}